<template>
  <div class="flex flex-col justify-center items-center my-8">
    <SvgIcon icon="Alert" size="lg" class="stroke-warning" />

    <span class="text-secondary text-sm mt-4">
      {{ $t('ui.common.oops_something_went_wrong') }}
    </span>

    <Button v-if="showButton" class="mt-4" size="sm" @click="reload">
      {{ $t('ui.common.try_again') }}
    </Button>
  </div>
</template>

<script setup lang="ts">
import Button from '@/components/controls/Button'
import SvgIcon from '@/components/misc/SvgIcon'

defineProps({
  showButton: {
    type: Boolean,
    default: true,
  },
})

function reload() {
  window.location.reload()
}
</script>
