<template>
  <router-link
    v-tippy="{
      trigger: link.comingSoon ? 'mouseenter focus' : 'manual',
      content: link.comingSoon ? $t('ui.common.coming_soon') : null,
    }"
    :to="link.comingSoon || !link.routeTo ? '#' : { name: link.routeTo }"
    class="sublink"
    :class="{
      active: isActive,
      'coming-soon': link.comingSoon,
      disabled: link.disabled,
    }"
    @click="$bus.emit('hideMobileSidebar')"
  >
    <span>{{ link.text }}</span>
  </router-link>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import type { NavLink } from '@/types/components/navigation'

export default defineComponent({
  props: {
    link: {
      type: Object as PropType<NavLink>,
      required: true,
    },
  },

  computed: {
    isActive() {
      return this.$route.matched.some(
        (route) => route.name === this.link.routeTo
      )
    },
  },
})
</script>
