<template>
  <div class="sidemenu">
    <div
      class="absolute top-0 left-0 bottom-0 w-1"
      :style="{ backgroundColor: settings.additional.profile_icon_color }"
    />

    <div
      v-if="$breakpoints.lgAndUp"
      class="sidebar-toggle"
      @click="globalStore.toggleSidebar()"
    >
      <SvgIcon icon="ArrowLeft" :class="{ collapsed: !sidebarToggled }" />
    </div>

    <transition mode="out-in" name="fade">
      <div v-if="sidebarToggled" class="mb-8">
        <div class="flex justify-between items-center ml-4 mr-3">
          <TogglePrivacyMode class="mx-3" />
          <UserMenu />
          <Notification
            v-if="hasPermission(239)"
            id="navigation-notifications"
          />
          <span v-else style="width: 42px" />
        </div>
      </div>
      <div v-else class="flex flex-col items-center mb-6">
        <AccountBadge
          :show-arrow="false"
          class="cursor-pointer"
          @click="globalStore.toggleSidebar()"
        />
        <TogglePrivacyMode class="mt-6" />
        <Notification
          v-if="hasPermission(239)"
          id="navigation-notifications"
          class="mt-4"
        />
      </div>
    </transition>

    <AccountSelection class="mx-4 mb-8" />

    <div v-if="!noPermissionsAdmin && !noPackages" class="flex-grow">
      <template v-if="isAdminView">
        <SidebarLink
          v-for="(link, index) in links"
          :key="'as-' + index"
          :link="link"
          :link-index="index"
        />
      </template>
    </div>

    <div v-if="$breakpoints.mdAndUp" class="mx-4 py-4 sticky bottom-0">
      <transition mode="out-in" name="fade">
        <div v-if="sidebarToggled" key="big">
          <DailyClose large class="py-2 mx-2" />
          <Currencies />
        </div>
        <div v-else key="small" class="flex flex-col items-center">
          <DailyClose class="py-2 -mx-2" />
          <SmallCurrencies @click="globalStore.toggleSidebar()" />
        </div>
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'

import { useGlobalStore } from '@/stores/global'
import { useUserStore } from '@/stores/user'

import SvgIcon from '@/components/misc/SvgIcon'

import AccountBadge from '../components/AccountBadge.vue'
import Currencies from '../components/Currencies.vue'
import DailyClose from '../components/DailyClose.vue'
import Notification from '../components/Notifications.vue'
import SmallCurrencies from '../components/SmallCurrencies.vue'
import TogglePrivacyMode from '../components/TogglePrivacyMode.vue'
import UserMenu from '../components/UserMenu.vue'

import SidebarLink from './SidebarLink.vue'
import AccountSelection from '../components/AccountSelection.vue'
import type { NavLink, ParentNavLink } from '@/types/components/navigation'
import type { TourFlow } from '@/types/services/user_settings'

export default defineComponent({
  components: {
    AccountSelection,
    Currencies,
    Notification,
    SidebarLink,
    SmallCurrencies,
    SvgIcon,
    TogglePrivacyMode,
    UserMenu,
    AccountBadge,
    DailyClose,
  },

  data() {
    return {
      globalStore: useGlobalStore(),
      userStore: useUserStore(),
    }
  },

  computed: {
    ...mapState(useGlobalStore, ['sidebarToggled']),
    ...mapState(useUserStore, [
      'settings',
      'currentView',
      'hasPackage',
      'noPermissionsAdmin',
      'noPackages',
      'activeTourFlow',
      'hasPermission',
    ]),
    isAdminView() {
      return this.currentView === 'admin'
    },
    links(): ParentNavLink[] {
      const { activeTourFlow } = this
      const links: ParentNavLink[] = [
        {
          routeTo: 'DashboardView',
          icon: 'Dashboard',
          text: this.$t('ui.common.dashboard'),
          children: [],
          visible:
            this.hasPermission(2) && this.hasPackage([2, 3, 5, 37, 43, 47]),
        },
        {
          routeTo: '',
          icon: 'Fund',
          text: this.$t('ui.common.fund'),
          visible: this.hasPermission(277) && this.hasPackage([3, 43]),
          children: [
            {
              text: this.$t('ui.common.clients'),
              routeTo: 'FundCrmView',
              visible: this.hasPermission(43),
            },
            {
              text: this.$t('ui.common.fees_and_referrers'),
              routeTo: 'FundFeesView',
              visible: this.hasPermission(53),
            },
            {
              text: this.$t('ui.common.series_of_shares'),
              routeTo: 'FundSeriesView',
              visible: this.hasPermission(61),
            },
          ],
        },
        {
          routeTo: '',
          icon: 'Portfolio',
          text: this.$t('ui.common.assets'),
          visible:
            this.hasPermission(3) && this.hasPackage([2, 3, 5, 37, 43, 47]),
          children: [
            {
              text: this.$t('ui.common.portfolio'),
              routeTo: 'AssetsPortfolioView',
              visible: this.hasPermission(5),
            },
            {
              text: this.$t('ui.common.asset_sources'),
              routeTo: 'AssetSourcesView',
              visible:
                this.hasPermission(19) ||
                this.hasPermission(7) ||
                this.hasPermission(13),
            },
          ],
        },
        // {
        //   routeTo: 'LiquidityProvidingView',
        //   icon: 'Pulse',
        //   text: this.$t('ui.common.defi'),
        //   // TODO: Temporary set to permission for Assets.
        //   // Change to permissions for DeFi after permissions refactoring.
        //   visible: this.hasPermission(3) && this.hasPackage([2, 3, 5, 37, 43, 47]),
        //   children: [
        //     // {
        //     //   text: this.$t('ui.common.liquidity_providing'),
        //     //   routeTo: 'LiquidityProvidingView',
        //     //   visible: this.hasPermission(3),
        //     // },
        //   ],
        // },
        {
          routeTo: '',
          icon: 'Accounting',
          text: this.$t('ui.common.accounting'),
          visible: this.hasPermission(149) && this.hasPackage([5, 47]),
          children: [
            {
              text: this.$t('ui.common.dashboard'),
              routeTo: 'AccountingDashboard',
              visible: this.hasPermission(229),
            },
            {
              text: this.$t('ui.common.contacts'),
              routeTo: 'AccountingContacts',
              visible: this.hasPermission(229),
            },
            {
              text: this.$t('ui.common.purchase_invoices'),
              routeTo: 'AccountingPurchaseInvoices',
              visible: this.hasPermission(229),
            },
            {
              text: this.$t('ui.common.sales_invoices'),
              routeTo: 'AccountingSalesInvoicesView',
              visible: this.hasPermission(229),
            },
            {
              text: this.$t('ui.common.reports_and_tools'),
              routeTo: 'AccountingReportView',
              visible: this.hasPermission(229),
            },
            {
              text: this.$t('ui.common.carts_of_accounts'),
              routeTo: 'AccountingChartOfAccounts',
              visible: this.hasPermission(229),
            },
            {
              text: this.$t('ui.common.transactions'),
              routeTo: 'AccountingTransactionsView',
              visible: this.hasPermission(229),
            },
            {
              text: this.$t('ui.common.journal'),
              routeTo: 'AccountingJournalView',
              visible: this.hasPermission(229),
            },
          ],
        },
        {
          routeTo: 'StatisticsPerformanceView',
          icon: 'Statistics',
          text: this.$t('ui.common.statistics'),
          visible:
            this.hasPermission(71) &&
            this.hasPermission(73) &&
            this.hasPackage([2, 3, 5, 37, 43, 47]),
          children: [],
          // children: [
          //   {
          //     text: this.$t('ui.common.performance'),
          //     routeTo: 'StatisticsPerformanceView',
          //     visible: this.hasPermission(73),
          //   },
          //   // TEMPORARILY DISABLED
          //   // {
          //   //   text: this.$t('ui.common.portfolio_maker'),
          //   //   routeTo: 'StatisticsPortfolioMakerView',
          //   //   visible: this.hasPermission(79)
          //   // },
          //   // {
          //   //   text: this.$t('ui.common.rebalance'),
          //   //   routeTo: 'StatisticsRebalanceView',
          //   //   visible: this.hasPermission(83)
          //   // },
          // ],
        },
        // {
        //   routeTo: '',
        //   icon: 'Trades',
        //   text: this.$t('ui.common.trades'),
        //   visible:
        //     this.hasPermission(89) && this.hasPackage([2, 3, 5, 37, 43, 47]),
        //   children: [
        //     {
        //       text: this.$t('ui.common.cex'),
        //       routeTo: 'CexView',
        //       visible: this.hasPermission(97),
        //     },
        //   ],
        // },
        {
          routeTo: '',
          icon: 'Settings2',
          text: this.$t('ui.common.manage'),
          visible: this.hasPermission(191),
          children: [
            {
              text: this.$t('ui.common.fund'),
              routeTo: 'SettingsFundsView',
              visible: this.hasPermission(223) && this.hasPackage([3, 43]),
            },
            {
              text: this.$t('ui.common.portfolios'),
              routeTo: 'SettingsPortfoliosView',
              // TODO: Add check permissions - this.hasPermission(XXX) &&
              visible: this.hasPackage([2, 37]),
            },
            {
              text: this.$t('ui.common.admins'),
              routeTo: 'SettingsAdminsView',
              visible:
                this.hasPermission(199) && this.hasPackage([3, 5, 43, 47]),
            },
            {
              text: this.$t('ui.common.accounting'),
              routeTo: 'AccountingSettingsView',
              visible: this.hasPermission(229) && this.hasPackage([5, 47]),
            },
          ],
        },
        {
          routeTo: '',
          icon: 'Services',
          text: this.$t('ui.common.services'),
          visible:
            this.hasPermission(331) && this.hasPackage([2, 3, 5, 37, 43, 47]),
          children: [
            {
              text: this.$t('ui.common.supported_services'),
              routeTo: 'SupportedServicesView',
              visible: this.hasPermission(283),
            },
            {
              text: this.$t('ui.common.monitoring'),
              routeTo: 'MonitoringView',
              visible: this.hasPermission(313),
            },
          ],
        },
      ]

      return links
        .filter((link) => link.visible)
        .map((link) => {
          link.children = link.children
            .filter((sublink) => sublink.visible)
            .map((sublink) => ({
              ...sublink,
              disabled: this.isDisabledByTour(sublink, activeTourFlow),
            }))
          link.disabled = this.isDisabledByTour(link, activeTourFlow)
          return link
        })
    },
  },

  methods: {
    isDisabledByTour(
      link: ParentNavLink | NavLink,
      currentFlow: TourFlow | null
    ) {
      if (
        !currentFlow ||
        currentFlow.scenario_key !== 'onboarding' ||
        currentFlow.current_step == 7
      ) {
        return false
      }

      if (currentFlow.current_step == 0) {
        return true
      }

      let allowedRouteName = 'AssetSourcesView'
      if (currentFlow.current_step < 3) {
        allowedRouteName = 'SettingsPortfoliosView'
      }

      if (link.routeTo === allowedRouteName) {
        return false
      }
      if ('children' in link && link.children.length) {
        return !link.children.some(
          (sublink) => sublink.routeTo === allowedRouteName
        )
      }

      return true
    },
  },
})
</script>
