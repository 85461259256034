<template>
  <div class="flex flex-col justify-center items-center my-4 opacity-50">
    <Spinner width="100" class="fill-brand" />
    <span class="text-secondary text-sm mt-2">
      {{ $t('ui.common.loading_view') }}
    </span>
  </div>
</template>

<script setup lang="ts">
import Spinner from '@/assets/loaders/Spinner.svg?component'
</script>
