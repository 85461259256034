<template>
  <div
    v-tippy="{
      trigger: link.comingSoon ? 'mouseenter focus' : 'manual',
      content: link.comingSoon ? $t('ui.common.coming_soon') : null,
    }"
    class="link-container"
    :class="[
      {
        active: isActive,
        'is-expanded': showSubmenu,
        'coming-soon': link.comingSoon,
        disabled: link.disabled,
      },
    ]"
  >
    <component
      :is="link.routeTo ? 'router-link' : 'div'"
      v-bind="link.routeTo ? { to: { name: link.routeTo } } : {}"
      class="link"
      @click="handleClick"
    >
      <keep-alive>
        <SvgIcon :icon="link.icon" class="icon" />
      </keep-alive>
      <transition name="link" appear>
        <div v-if="globalStore.sidebarToggled" class="link-label">
          <span>{{ link.text }}</span>
          <SvgIcon
            v-if="link.children.length"
            icon="ArrowDown"
            class="icon-arrow"
          />
        </div>
      </transition>
    </component>

    <transition name="sublink" appear>
      <div v-if="link.children.length && showSubmenu" class="sublink-container">
        <SidebarSublink
          v-for="(sublink, i) in link.children"
          :key="i"
          :link="sublink"
        />
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { computed, type PropType, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useEventBus } from '@/composables/event_bus'
import { useGlobalStore } from '@/stores/global'
import SvgIcon from '@/components/misc/SvgIcon'
import SidebarSublink from './SidebarSublink.vue'
import type { ParentNavLink } from '@/types/components/navigation'

const props = defineProps({
  link: {
    type: Object as PropType<ParentNavLink>,
    required: true,
  },
  linkIndex: {
    type: Number,
    default: null,
  },
})

const showSubmenu = ref(false)
const fromToggled = ref(false)

const eventBus = useEventBus()
const route = useRoute()
const globalStore = useGlobalStore()

const isActive = computed(() => {
  return (
    isChildActive.value ||
    route.matched.some((match) => match.name === props.link.routeTo)
  )
})

const isChildActive = computed(() => {
  let childActive = false

  for (const ch of props.link.children) {
    route.matched.forEach((match) => {
      if (match.name === ch.routeTo) {
        childActive = true
      }
    })
  }

  return childActive
})

const isCollapsed = computed(() => {
  return (
    props.linkIndex === globalStore.collapsedMenuIndex &&
    props.link.children.length
  )
})

watch(isCollapsed, (isCollapsed) => {
  if (isCollapsed && globalStore.sidebarToggled) {
    const time = !fromToggled.value ? 500 : 0

    setTimeout(() => {
      showSubmenu.value = true
    }, time)
  } else {
    showSubmenu.value = false
  }
})

watch(
  () => globalStore.sidebarToggled,
  (sidebarToggled) => {
    if (!sidebarToggled) {
      showSubmenu.value = false
    }
  }
)

watch(
  isActive,
  (isActive) => {
    if (isActive) {
      setCollapsedIndex()
    }
  },
  { immediate: true }
)

function setCollapsedIndex() {
  globalStore.collapsedMenuIndex = props.linkIndex
}

function handleClick() {
  if (props.link.routeTo) {
    eventBus.emit('hideMobileSidebar')
  } else {
    toggle()
  }
}

function toggle() {
  fromToggled.value = globalStore.sidebarToggled
  const newLinkIndex = isCollapsed.value ? null : props.linkIndex

  if (!globalStore.sidebarToggled) {
    globalStore.toggleSidebar()
  }

  globalStore.collapsedMenuIndex = newLinkIndex
}
</script>
