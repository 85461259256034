<template>
  <div class="mobile-sidebar" :class="{ 'mobile-sidebar-collapsed': !show }">
    <transition name="fade">
      <div v-if="show" class="mobile-sidebar-cover" @click="hideMenu" />
    </transition>
    <div class="mobile-sidebar-drawer">
      <router-link class="home-link" :to="{ name: homeRoute }">
        <LogoFullWhite v-if="colorScheme.isDarkTheme" class="logo" />
        <LogoFull v-else class="logo" />
      </router-link>

      <div
        class="absolute pa-1 cursor-pointer top-6 right-6 text-secondary hover:text-brand"
        @click="hideMenu"
      >
        <SvgIcon icon="Close" />
      </div>

      <div class="overflow-y-auto overflow-x-hidden flex-grow">
        <SideMenu class="min-h-full" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'
import { useColorScheme } from '@/composables/color_scheme'
import { useUserStore } from '@/stores/user'
import LogoFull from '@/assets/images/LogoFull.svg?component'
import LogoFullWhite from '@/assets/images/LogoFullWhite.svg?component'
import SvgIcon from '@/components/misc/SvgIcon'
import SideMenu from '../SideMenu/Index.vue'

export default defineComponent({
  components: {
    LogoFull,
    LogoFullWhite,
    SvgIcon,
    SideMenu,
  },

  data() {
    return {
      show: false,
      colorScheme: useColorScheme(),
    }
  },

  computed: {
    ...mapState(useUserStore, {
      homeRoute: (store) => store.homeRoute,
      currentView: (store) => store.currentView,
    }),
    isAdminView() {
      return this.currentView === 'admin'
    },
  },

  mounted() {
    this.$bus.on('showMobileSidebar', this.showMenu)
    this.$bus.on('hideMobileSidebar', this.hideMenu)
  },

  beforeUnmount() {
    this.$bus.off('showMobileSidebar', this.showMenu)
    this.$bus.off('hideMobileSidebar', this.hideMenu)
  },

  methods: {
    showMenu() {
      this.show = true
    },
    hideMenu() {
      this.show = false
    },
  },
})
</script>
