import { onMounted, onUnmounted, ref, watch } from 'vue'
import { useUserStore } from '@/stores/user'

export function useColorScheme() {
  const userStore = useUserStore()
  const isDarkTheme = ref(getIsDarkTheme())

  function getIsDarkTheme() {
    const userTheme = userStore.settings.additional.theme
    const prefersDark = window.matchMedia(
      '(prefers-color-scheme: dark)'
    ).matches

    return (
      ['dark', 'black'].includes(userTheme) ||
      (userTheme == 'system' && prefersDark)
    )
  }

  function updateTheme() {
    isDarkTheme.value = getIsDarkTheme()
  }

  onMounted(() => {
    updateTheme()
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', updateTheme)
  })

  onUnmounted(() => {
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .removeEventListener('change', updateTheme)
  })

  watch(() => userStore.settings.additional.theme, updateTheme)

  return {
    isDarkTheme,
  }
}
