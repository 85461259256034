import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  stroke: "#6E84A1",
  "stroke-linecap": "round",
  "stroke-linejoin": "round",
  "stroke-width": "1.4",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M8 1.936v12.128M8 1.936 5.979 3.957M8 1.937l2.021 2.02M8 14.064l2.021-2.021M8 14.063l-2.021-2.02M1.936 8h12.128M1.936 8l2.021 2.021M1.937 8l2.02-2.021M14.064 8l-2.021-2.021M14.063 8l-2.02 2.021" }, null, -1 /* HOISTED */)
  ])))
}
export default { render: render }